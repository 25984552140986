import { Injectable } from '@angular/core';
import { HttpUtilsService } from './http-utils.service';
import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { TokenStorageService } from './token-storage.service';
import { CemeteryInfoResult } from '../interfaces/cemetery-info';
import { Token } from '../enums/token.enum';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { webSocket } from 'rxjs/webSocket';
import { WebSocketSubject } from 'rxjs/internal-compatibility';

export class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value).replace(/%2C/g, ',');
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({
  providedIn: 'root',
})
export class AdvanceTableServiceService {
  constructor(
    private httpUtilsService: HttpUtilsService,
    private httpClient: HttpClient,
    private readonly httpUtils: HttpUtilsService,
    private tokenStorage: TokenStorageService
  ) { }

  storeFilterAdvTable(formValue: any) {
    if (formValue && !this.tokenStorage.get(Token.advTableFilter)) {
      this.tokenStorage.store(Token.advTableFilter, JSON.stringify(formValue));
      return;
    }
    this.tokenStorage.store(Token.advTableFilter, JSON.stringify(formValue));
  }

  clearFilterAdvTable() {
    // localStorage.removeItem('advTableFilter');
    this.tokenStorage.clear(Token.advTableFilter);
  }

  getFilterAdvTable() {
    if (this.tokenStorage.get(Token.advTableFilter)) {
      return JSON.parse(this.tokenStorage.get(Token.advTableFilter));
    }
    return null;
  }

  storeAdvTableSort(sort: any) {
    if (sort && !this.tokenStorage.get(Token.advTableSort)) {
      this.tokenStorage.store(Token.advTableSort, JSON.stringify(sort));
      return;
    }
    this.tokenStorage.store(Token.advTableSort, JSON.stringify(sort));
  }

  getSortAdvTable() {
    if (this.tokenStorage.get(Token.advTableSort)) {
      return JSON.parse(this.tokenStorage.get(Token.advTableSort));
    }
    return null;
  }

  clearSortAdvTable() {
    this.tokenStorage.clear(Token.advTableSort);
  }

  getPlotsData(queryParams?: any) {
    // GET {{baseUrl}}/v1/adv_table/plots/
    const url = this.httpUtilsService.getResourceUrl(['adv_table', 'plots']);
    let params = new HttpParams();

    if (queryParams) {
      params = new HttpParams({ fromObject: queryParams });
    }
    return this.httpClient.get(url, { params });
  }

  getRoisData(queryParams?: any) {
    // GET {{baseUrl}}/v1/adv_table/application_records/
    const url = this.httpUtilsService.getResourceUrl(['adv_table', 'application_records']);
    let params = new HttpParams();
    if (queryParams) {
      params = new HttpParams({ fromObject: queryParams });
    }
    return this.httpClient.get(url, { params });
  }

  getIntermentsData(queryParams?: any) {
    // GET {{baseUrl}}/v1/adv_table/interments/
    const url = this.httpUtilsService.getResourceUrl(['adv_table', 'interments']);
    let params = new HttpParams();
    if (queryParams) {
      params = new HttpParams({ fromObject: queryParams });
    }
    return this.httpClient.get(url, { params });
  }

  getBusinessData(queryParams?: any) {
    // GET {{baseUrl}}/v1/adv_table/business/
    const url = this.httpUtilsService.getResourceUrl(['adv_table', 'business']);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    if (queryParams) {
      params = new HttpParams({ fromObject: queryParams, encoder: new CustomEncoder() });
    }
    return this.httpClient.get(url, { params });
  }

  getPersonData(queryParams?: any) {
    // GET{ base_url } api/v1/adv_table/persons
    const url = this.httpUtilsService.getResourceUrl(['adv_table', 'persons']);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    if (queryParams) {
      params = new HttpParams({ fromObject: queryParams, encoder: new CustomEncoder() });
    }
    return this.httpClient.get(url, { params });
  }

  getAllCemeteriesOptions(queryParams) {
    // {{baseUrl}}/v1/list-filter/cemetery/
    const url = this.httpUtilsService.getResourceUrl(['list-filter', 'cemetery']);
    let params = new HttpParams();
    if (queryParams) {
      params = new HttpParams({ fromObject: queryParams });
    }
    return this.httpClient.get(url, { params });
  }

  getSectionsOptions(queryParams) {
    // {{baseUrl}}/v1/list-filter/section/
    const url = this.httpUtilsService.getResourceUrl(['list-filter', 'section']);
    let params = new HttpParams();
    if (queryParams) {
      params = new HttpParams({ fromObject: queryParams });
    }
    return this.httpClient.get(url, { params });
  }

  createNewSection(value: any) {
    const url = this.httpUtils.getResourceUrl(['adv_table', 'sections']);
    return this.httpClient.post<any>(url, value);
  }

  deleteSection(plotId: any) {
    const url = this.httpUtils.getResourceUrl(['adv_table', 'sections', plotId]);

    return this.httpClient.delete(url);
  }

  getRowsOptions(queryParams) {
    // {{baseUrl}}/v1/list-filter/row/
    const url = this.httpUtilsService.getResourceUrl(['list-filter', 'row']);
    let params = new HttpParams();
    if (queryParams) {
      params = new HttpParams({ fromObject: queryParams });
    }
    return this.httpClient.get(url, { params });
  }

  getNumberOptions(queryParams) {
    const url = this.httpUtilsService.getResourceUrl(['list-filter', 'number']);
    let params = new HttpParams();
    if (queryParams) {
      params = new HttpParams({ fromObject: queryParams });
    }
    return this.httpClient.get(url, { params });
  }

  getCemeteriesByOrganization(queryParams) {
    const accessToken = this.tokenStorage.get(Token.access);
    const url = this.httpUtils.getResourceUrl(['ms', 'cemeteries-by-org']);

    const params = new HttpParams({ fromObject: queryParams });

    return this.httpClient
      .get<CemeteryInfoResult>(url, { headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` }), params })
      .pipe(map(response => ({ data: response.data, pagination: { ...response.paginate } })));
  }

  connectToWebSocketFreemium(userId: string): WebSocketSubject<any> {
    const url = `${environment.WSS_URL}/ws/record_limit/${userId}/`;

    return webSocket(url);
  }
}
